
function Resume() {
    // return (
    //     <div className="NormalPageLayout">
    //         <p className="HeaderTitleNoHover">
    //             <ArticleSharpIcon fontSize='inherit' /> RESUMES
    //         </p><br />
    //         <a href={softwareEngineerPDF} className="HeaderTitle" target="_blank" rel="noreferrer">
    //             <DownloadIcon fontSize='inherit' /> Software Engineering
    //         </a><p /><br />
    //         <a href={gameDesignPDF} className="HeaderTitle" target="_blank" rel="noreferrer">
    //             <DownloadIcon fontSize='inherit' /> Game Development
    //         </a>

    //         <br />
    //     </div >
    // );
}

export { Resume };