

function ResentfulLungeDesignTemplate() {
    return (
        <div>
            <hr className="HorizontalLineBlue" />
            <b className="AbilityHeader animationcolorWhiteOrangeFlashing">DESIGN INSIGHTS</b>
            <hr className="HorizontalLineBlue" />
            <div className="animationFadeIn">
                Serving as an alternative, and risky, ability to generate additional Qi, Resentful Lunge further
                reinforces the aggressive design of this support champion.
            </div>
            <br />
            <b className="AbilityHeaderSmall">WHY GIVE HER A MELEE DASH?</b>
            <div className="animationFadeIn">
                Yingyue is always meant to give both the player and their opponent choice. How risky should Yingyue play?
                Well, obviously she gains significant Qi generation if she is using her Resentful Lunge on enemy champions.
                However, this opens her up to great risk as she isn't a very tough character in terms of defense.
                <p />
                What may not be immediately obvious, is that there is actually a secondary use for her leap as it can be cast upon
                allied minions and champions, granting her an escape at the cost of losing the bonus Qi or gap closing.
                <p />
                She could choose to empower her Resentful Lunge to get in and then use the free secondary charge to get out, but then
                she wouldn't be able to use it on her Jade Reach or Insatiable Touch, denying her either significant healing or the
                ability to supercharge her ally.
            </div>
            <br />
            <b className="AbilityHeaderSmall">THEMING</b>
            <div className="animationFadeIn">
                Jiangshi are also known as "Hopping Vampires". This ability is meant
                to evoke that same concept of a supernatural being moving in ways that seem
                unnatural and uncanny. Imagine for a moment, the difference between American
                and Japanese horror movies. American horror is usually relatively grounded and
                explained by the end, while Japanese horror monsters are beings that do not
                play by the same rules as mortals, moving and teleporting in nightmarish ways.
                <br />
                This ability also further reinforces her being a magical/supernatural being by allowing her to steal Qi
                at a distance with glowing Jade magic.
            </div>
            <br />
        </div >
    )
}

export { ResentfulLungeDesignTemplate };